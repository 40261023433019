<template>
  <router-link :to="'/player/video/' + video.id.videoId">
    <div class="o2-video">
      <h5>{{ video.snippet.title }}</h5>
      <img :src="video.snippet.thumbnails.medium.url" :alt="video.snippet.title" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "Video",
  props: {
    video: Object
  }
};
</script>

<style scoped>
div.o2-video {
  position: relative;
  margin: 1rem auto;
  border: 0.1rem solid white;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s linear;
  opacity: 0.8;
}

div.o2-video:hover {
  opacity: 1;
  transform: scale(1.02);
}

div.o2-video a {
  color: silver;
  text-decoration: none;
}

div.o2-video a:hover {
  color: white;
}

div.o2-video:hover h5 {
  opacity: 0;
}

div.o2-video h5 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  color: white;
  font-size: 0.8rem;
  opacity: 1;
  transition: all 0.2s linear;
}

img {
  width: 100%;
  border-radius: 0.5rem;
}
</style>