<template>
  <VideoContainer />
</template>

<script>
import VideoContainer from "@/components/pages/videos/VideoContainer.vue";

export default {
  name: "VideoPage",
  components: {
    VideoContainer
  }
};
</script>
