<template>
  <div class="row">
    <div
      class="col-xl-3 col-lg-4 col-sm-6 col-xs-12"
      :key="video.id.videoId"
      v-for="video in videos"
    >
      <Video :video="video" />
    </div>
  </div>
</template>

<script>
import Video from "./Video";

export default {
  name: "Videos",
  components: {
    Video
  },
  props: {
    videos: Array
  }
};
</script>
